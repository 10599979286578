import analytics from '@capturi/analytics'
import {
  GetSegmentDependentsResponseModel,
  SavedPhoneFilter,
  filtersAPI,
  useDeleteFilter,
  useSavedFilters,
  useUpdateSavedFilter,
} from '@capturi/api-filters'
import { DependentItem } from '@capturi/api-shared'
import Icon_EmptyState from '@capturi/assets/images/EmptyState.svg'
import { useCurrentUser } from '@capturi/core'
import {
  CreateUpdateSegmentDialog,
  FilterSubscribeSwitch,
  FilterTags,
  SingleSegmentContainer,
  useFilterDefinitions,
  useSegmentStatesContext,
} from '@capturi/filters'
import { ErrorBoundary } from '@capturi/react-utils'
import request from '@capturi/request'
import { useUsers } from '@capturi/stores'
import {
  Button,
  ButtonProps,
  ContentPlaceholder,
  List,
  ListItem,
  ListItemMenu,
  MenuItem,
  MenuList,
  useToast,
} from '@capturi/ui-components'
import { useModal } from '@capturi/use-modal'
import { Box, Flex, Stack, Text, Tooltip } from '@chakra-ui/react'
import { i18n } from '@lingui/core'
import { Trans, t } from '@lingui/macro'
import CancelActionModal from 'components/CancelActionModal'
import { DependentsInfoDialog } from 'components/DependentsInfoDialog'
import { ButtonLink } from 'components/Link'
import { routes as conversationRoutes } from 'pages/Conversations'
import NotFoundPage from 'pages/NotFoundPage'
import { getRoutes as getAnalyticsRoutes } from 'pages/analytics'
import React from 'react'
import { MdDelete, MdEdit } from 'react-icons/md'
import { useNavigate } from 'react-router'
import { useToggle } from 'react-use'

const analyticsRoutes = getAnalyticsRoutes()

const ToggleFilterTagsButton: React.FC<
  {
    value: boolean
  } & Omit<ButtonProps, 'children' | 'value'>
> = ({ value, ...props }) => {
  return (
    <Button variant="link" size="sm" {...props}>
      {value ? <Trans>Hide filters</Trans> : <Trans>Show filters</Trans>}
    </Button>
  )
}

const FilterRow: React.FC<{
  filter: SavedPhoneFilter
  showFilterTags: boolean
}> = ({ filter, showFilterTags }) => {
  const { getUserByUid } = useUsers()
  const toast = useToast()
  const { mutate: updateFilter } = useUpdateSavedFilter()

  const subscribeDesc =
    filter.accessLevel === 'Edit'
      ? t`Receive a daily summary by e-mail with conversations in this segment`
      : 'You don’t have edit rights for this segment. Without edit rights, notifications are not allowed.'

  const handleSubscriptionChange = (subscribe: boolean): void => {
    const analyticsText =
      subscribe === true ? 'SegmentSubscribed' : 'SegmentUnsubscribed'
    updateFilter(
      {
        uid: filter.uid,
        filter: {
          subscribe,
        },
      },
      {
        onSuccess: (updatedSavedFilter) => {
          toast({
            title: updatedSavedFilter.isSubscribed
              ? t`Notifications are enabled for this segment.`
              : t`Notifications are disabled for this segment.`,
            status: 'success',
          })
          analytics.event(analyticsText)
        },
        onError: () => {
          toast({
            title: t`Could not update subscription status`,
            status: 'error',
          })
        },
      },
    )
  }
  return (
    <Flex direction="column" w="full">
      <Flex align="center" wrap="wrap">
        <Text flex={1} mr={4}>
          {filter.name}
        </Text>

        <Stack direction="row" spacing={4} shouldWrapChildren align="center">
          <Text color="gray.600">
            <Trans>Updated by</Trans>{' '}
            {getUserByUid(filter.updatedByUserUid).name}
            {', '}
            {i18n.date(filter.updatedOn, { dateStyle: 'long' })}
          </Text>
          <Tooltip label={subscribeDesc} aria-label={subscribeDesc}>
            <Flex justify="center" align="center">
              <FilterSubscribeSwitch
                isChecked={filter.isSubscribed}
                cursor="pointer"
                isDisabled={filter.accessLevel !== 'Edit'}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleSubscriptionChange(e.target.checked)
                }
                onClick={(e) => e.stopPropagation()}
                id={`email-alerts-${filter.uid}`}
                size="xs"
              />
            </Flex>
          </Tooltip>
        </Stack>
      </Flex>
      {showFilterTags && (
        <Box pt="2" pb="4">
          <FilterTags values={filter.values} />
        </Box>
      )}
    </Flex>
  )
}

const SavedPhoneFilters: React.FC = () => {
  const navigate = useNavigate()
  const toast = useToast()
  const currentUser = useCurrentUser()
  const filterDefinitions = useFilterDefinitions(currentUser)
  const { reinitWithSavedPhoneFilter } = useSegmentStatesContext()

  const { mutate: deleteFilter } = useDeleteFilter()

  const [openCreateUpdateSegmentDialog] = useModal(CreateUpdateSegmentDialog)
  const [showCancelActionModal] = useModal(CancelActionModal)
  const [openSegmentDependentsDialog] = useModal(DependentsInfoDialog)

  const { data: savedFilters } = useSavedFilters()

  const [activeShowFilterTags, activeToggleShowFilterTags] = useToggle(false)

  if (!currentUser.permissions.editSegment) return <NotFoundPage />

  const userCanEdit = currentUser.isAdmin || currentUser.isTeamLead
  const userCanAccessAnalytics = userCanEdit

  const handleDeleteSavedFilter = async (
    savedFilter: SavedPhoneFilter,
  ): Promise<void> => {
    deleteFilter(savedFilter.uid, {
      onSuccess: () => {
        toast({
          title: t`Segment deleted`,
          status: 'success',
        })
      },
      onError: () => {
        toast({
          title: t`An error occurred`,
          description: t`The segment was not deleted`,
          status: 'error',
        })
      },
    })
  }

  const ensureNoDependents = async (
    uid: string,
  ): Promise<{
    hasDependents: boolean
    dependents: DependentItem[]
  }> => {
    try {
      const resp = await request<GetSegmentDependentsResponseModel>(
        filtersAPI.getDependents(uid),
      )
      return {
        hasDependents: resp.dependents.length > 0,
        dependents: resp.dependents,
      }
    } catch {
      return {
        hasDependents: false,
        dependents: [],
      }
    }
  }

  const handleDeleteFilterClicked = async (
    savedFilter: SavedPhoneFilter,
  ): Promise<void> => {
    const { hasDependents, dependents } = await ensureNoDependents(
      savedFilter.uid,
    )
    if (hasDependents) {
      openSegmentDependentsDialog({
        heading: t`The segment cannot be deleted`,
        description: t`Remove references to this segment from the following dashboards and scores before it can be deleted.`,
        dependents,
      })
      return
    }
    try {
      showCancelActionModal({
        action: () => handleDeleteSavedFilter(savedFilter),
        label: t`Deleting segment...`,
        description: t`You will no longer be able to use it in filters, dashboards, scores etc...`,
      })
    } catch {
      // cancelled
    }
  }

  const handleCreateOrUpdateFilter = (savedFilter: SavedPhoneFilter): void => {
    openCreateUpdateSegmentDialog({ filter: savedFilter, state: undefined })
  }

  const applyFilterOnPage = (
    filter: SavedPhoneFilter,
    pagePath: string,
  ): void => {
    reinitWithSavedPhoneFilter(filter)
    navigate(pagePath)
  }

  function renderPlaceholder(): React.ReactElement {
    return (
      <ContentPlaceholder.Container mt="5vh" maxW="100%">
        <Box w="full" maxW="28rem">
          <ContentPlaceholder.Image as={Icon_EmptyState} />
          <ContentPlaceholder.Heading>
            <Trans>It&apos;s a bit empty here!</Trans>
          </ContentPlaceholder.Heading>
        </Box>
        <ContentPlaceholder.Body>
          <Box w="full" maxW="28rem">
            <Text>
              <Trans>
                Segments are a collection of filters which can be created and
                applied on the conversation and analytics pages. Sign up for
                notifications and get notified automatically when conversations
                match your filtering.
              </Trans>
            </Text>
            <Text mt={4}>
              <Trans>
                You can create segments, such as the one below, via any of the
                filters on the analytics pages. Create your first segment by
                adding a filter and saving the segment.
              </Trans>
            </Text>
          </Box>
          <Flex
            background="white"
            border="3px dashed"
            borderColor="gray.300"
            py={4}
            px={8}
            borderRadius="lg"
            justifyContent="center"
            mt={8}
            color="text"
            textAlign="left"
          >
            <SingleSegmentContainer filterDefinitions={filterDefinitions} />
          </Flex>
          <Text mt={8}>
            <Trans>
              ...or go to trackers and make your first segment there.
            </Trans>
          </Text>
          <Flex mt={4}>
            <ButtonLink primary to={analyticsRoutes.trackers.main()}>
              <Trans>Go to trackers</Trans>
            </ButtonLink>
          </Flex>
        </ContentPlaceholder.Body>
      </ContentPlaceholder.Container>
    )
  }

  return (
    <ErrorBoundary>
      <Box>
        {savedFilters?.length === 0 ? (
          renderPlaceholder()
        ) : (
          <Box>
            <Flex justify="space-between" mb="2">
              <Text color="textMuted">
                <Trans>
                  Access saved segments and manage e-mail notifications
                </Trans>
              </Text>
              <ToggleFilterTagsButton
                value={activeShowFilterTags}
                onClick={activeToggleShowFilterTags}
              />
            </Flex>
            <List>
              {savedFilters?.map((x) => (
                <ListItem
                  key={x.uid}
                  hasDivider
                  button={userCanAccessAnalytics}
                  onClick={() =>
                    userCanAccessAnalytics &&
                    applyFilterOnPage(x, conversationRoutes.list())
                  }
                  defaultHideSecondaryAction
                >
                  <FilterRow filter={x} showFilterTags={activeShowFilterTags} />
                  <ListItemMenu placement={'bottom-end'} isLazy>
                    <Tooltip
                      label={t`You don’t have edit rights for this segment`}
                      isDisabled={x.accessLevel === 'Edit'}
                      hasArrow
                      placement="top"
                    >
                      <MenuList>
                        <MenuItem
                          onClick={() => handleCreateOrUpdateFilter(x)}
                          isDisabled={x.accessLevel !== 'Edit'}
                        >
                          <MdEdit />
                          <Text ml="2">
                            <Trans>Edit</Trans>
                          </Text>
                        </MenuItem>
                        <MenuItem
                          onClick={() => handleDeleteFilterClicked(x)}
                          isDisabled={x.accessLevel !== 'Edit'}
                        >
                          <MdDelete />
                          <Text ml="2">
                            <Trans>Delete</Trans>
                          </Text>
                        </MenuItem>
                      </MenuList>
                    </Tooltip>
                  </ListItemMenu>
                </ListItem>
              ))}
            </List>
          </Box>
        )}
      </Box>
    </ErrorBoundary>
  )
}

export default SavedPhoneFilters
